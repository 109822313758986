import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import Logotype from 'Components/Logotype';

const Address = styled('address')``;
const Hr = styled('hr')``;

const Wrapper = ({ className }) => (
  <section
    className={className}
    itemScope
    itemType="http://schema.org/Organization"
  >
    <Logotype inverted itemProp="logo" />
    <Address itemProp="address">
      2627 Hanover St.
      <br />
      Palo Alto, CA 94304, United States
      <Hr />
    </Address>
  </section>
);

Wrapper.propTypes = {
  className: PropTypes.string,
};

export default styled(Wrapper)`
  align-items: center;
  align-self: flex-start;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
  justify-content: space-between;

  ${Logotype} {
    max-height: 4rem;
    text-align: center;
    width: 100%;
  }

  ${Address} {
    border-bottom: 0.1rem solid rgba(0, 53, 102, 0.05);
    color: #000f19;
    font-size: 1.4rem;
    font-style: normal;
    line-height: 1.8rem;
    margin: 0 1.6rem;
    opacity: 0.6;
    text-align: center;
    width: 100%;
    padding: 2.4rem 0;

    ${Hr} {
      display: none;
    }
  }

  @media screen and (min-width: 600px) {
    ${Logotype} {
      margin-right: 2rem;
      width: auto;
    }

    ${Address} {
      border: none;
      text-align: left;
      width: auto;
      padding: 0;

      ${Hr} {
        margin-left: 0;
        display: block;
        width: 38%;
      }
    }
  }
`;
