import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from '@prop-types';
import { Helmet } from 'react-helmet';
import { intlDefault, withIntl } from '@react-intl';

const SEO = ({ article, image, intl, pathname }) => (
  <StaticQuery
    query={graphql`
      query SEO {
        site {
          siteMetadata {
            defaultDescription: description
            defaultImage: image
            defaultTitle: title
            description
            keywords
            robots
            siteUrl
            title
            titleTemplate
            twitterUsername
            type
          }
        }
      }
    `}
    render={({
      site: {
        siteMetadata: {
          defaultDescription,
          defaultImage,
          defaultTitle,
          description,
          keywords,
          robots,
          siteUrl,
          title,
          titleTemplate,
          twitterUsername,
          type,
        },
      },
    }) => {
      const seo = {
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        title: title || defaultTitle,
        type,
        url: `${siteUrl}${pathname || '/'}`,
      };

      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            {/* Set the document's language */}
            <html lang={intl.locale} />
            {/* Set the document's title */}
            <title>{title}</title>
            {/* Short description of the document (limit to 150 characters) */}
            {/* This content *may* be used as a part of search engine results. */}
            <meta name="description" content={description} />¡{/* Meta data */}
            {/* Schema.org meta information */}
            <meta property="og:site_name" content="Joinedapp" />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && (
              <meta property="og:type" content="article" />
            )}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            {seo.type && <meta property="og:type" content={seo.type} />}
            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && (
              <meta name="twitter:creator" content={twitterUsername} />
            )}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            <meta name="keywords" content={keywords} />
            <meta name="HandheldFriendly" content="True" />
            {/* Control the behavior of search engine crawling and indexing */}
            <meta name="robots" content={robots} />
            <link rel="canonical" href="https://joined.app" />
          </Helmet>
        </>
      );
    }}
  />
);

SEO.propTypes = {
  article: PropTypes.bool,
  image: PropTypes.string,
  intl: PropTypes.intl,
  pathname: PropTypes.string,
};

SEO.defaultProps = {
  article: false,
  image: null,
  intl: intlDefault,
  pathname: null,
};

export default withIntl(SEO);
