import React from 'react';
import PropTypes from 'prop-types';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import styled from '@styled-components';

const Img = styled('img')``;
const Link = styled(OutboundLink)``;

const Media = ({ className }) => (
  <section
    className={className}
    itemScope
    itemType="http://schema.org/Organization"
  >
    <Link
      href="https://linkedin.com/company/joined-app"
      itemProp="url"
      name="Joinedapp"
      rel="noopener"
      sameas="https://www.joined.app"
      target="_blank"
    >
      <Img alt="LinkedIn" src="/images/linkedin.svg" />
    </Link>
    <Link
      href="https://facebook.com/joinedapp"
      itemProp="url"
      name="Joinedapp"
      rel="noopener"
      sameas="https://www.joined.app"
      target="_blank"
    >
      <Img alt="Facebook" src="/images/facebook.svg" />
    </Link>
    <Link
      href="https://twitter.com/joinedapp"
      itemProp="url"
      name="Joinedapp"
      rel="noopener"
      sameas="https://www.joined.app"
      target="_blank"
    >
      <Img alt="Twitter" src="/images/twitter.svg" />
    </Link>
    <Link
      href="https://www.instagram.com/joinedapp"
      itemProp="url"
      name="Joinedapp"
      rel="noopener"
      sameas="https://www.joined.app"
      target="_blank"
    >
      <Img alt="Instagram" src="/images/instagram.svg" />
    </Link>
  </section>
);

Media.propTypes = {
  className: PropTypes.string,
};

export default styled(Media)`
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  justify-content: space-evenly;

  ${Link} {
    margin-left: 2rem;
    height: 1.6rem;
  }
`;
