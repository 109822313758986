import React from 'react';
import { withPrefix } from 'gatsby';

import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Logotype = ({ className, inverted = false }) => (
  <img
    alt="Joinedapp"
    className={className}
    itemProp="logo"
    src={withPrefix(
      `/images/${inverted ? 'logotype-inverted' : 'logotype'}.svg`,
    )}
  />
);

Logotype.propTypes = {
  className: PropTypes.string,
  inverted: PropTypes.bool,
};

export default styled(Logotype)`
  align-items: center;
  display: flex;
  justify-content: center;
`;
