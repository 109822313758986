function capitalize(string) {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}

function identity(string) {
  return string;
}

function uppercase(string) {
  return string.toUpperCase();
}

function transform(
  string,
  { capitalize: needCapitalize, uppercase: needUppercase } = {},
) {
  let filter;

  switch (true) {
    case needCapitalize:
      filter = capitalize;
      break;
    case needUppercase:
      filter = uppercase;
      break;
    default:
      filter = identity;
      break;
  }

  return filter(string);
}

export default transform;
