import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';
import { FormattedMessage } from '@react-intl';

const GDPR = 'gdpr';

const Agree = styled('button')``;
const Decline = styled('button')``;

const Actions = styled('section')``;
const Content = styled('section')``;
const Disclaimer = styled('span')``;
const Cookies = styled('span')``;

const Wrapper = styled('section')``;

class Gdpr extends Component {
  static disableTracking() {
    window['ga-disable-UA-116232571-1'] = true;
  }

  static enableTracking() {
    window['ga-disable-UA-116232571-1'] = false;
  }

  static propTypes = {
    className: PropTypes.string,
  };

  state = { show: false };

  async componentDidMount() {
    const storage = await import('Providers/storage').catch(() => ({}));
    this.storage = storage.default;

    const gdpr = this.storage.get(GDPR);

    if (!gdpr) {
      Gdpr.disableTracking();
    }
    this.setState({ show: gdpr === undefined || gdpr === null });
  }

  disableTracking = () => {
    this.storage.set(GDPR, false);
    this.setState({ show: false });
  };

  enableTracking = () => {
    Gdpr.enableTracking();
    this.storage.set(GDPR, true);
    this.setState({ show: false });
  };

  render() {
    const { className } = this.props;
    const { show } = this.state;

    return (
      show && (
        <section className={className}>
          <Wrapper>
            <Content>
              <FormattedMessage capitalize component={Cookies} id="COOKIES" />
              <FormattedMessage
                capitalize
                component={Disclaimer}
                id="DISCLAIMER"
              />
            </Content>
            <Actions>
              <FormattedMessage
                capitalize
                component={Decline}
                id="DECLINE"
                onClick={this.disableTracking}
              />
              <FormattedMessage
                capitalize
                component={Agree}
                id="AGREE"
                onClick={this.enableTracking}
              />
            </Actions>
          </Wrapper>
        </section>
      )
    );
  }
}

export default styled(Gdpr)`
  background-color: #e6f2ff;
  box-sizing: border-box;
  height: 20rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;

  ${Wrapper} {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
    padding: 2.4rem 1.6rem 1.6rem;
    position: relative;

    ${Content} {
      font-size: 1.6rem;
      min-width: 25rem;

      ${Disclaimer} {
        display: block;
        line-height: 1.5;
        margin-left: 0;
        margin-top: 0.8rem;
      }
      ${Disclaimer} {
        display: block;
        line-height: 1.5;
        margin-left: 0;
        margin-top: 0.8rem;
        opacity: 0.6;
      }
    }

    ${Actions} {
      display: flex;
      flex: 1;
      justify-content: space-between;
      margin-top: 0.8rem;

      ${Agree} {
        background-image: linear-gradient(69deg, #005dff, #0095ff);
        border-radius: 0.6rem;
        border: solid 0.1rem #0095ff;
        color: white;
        cursor: pointer;
        font-size: 1.4rem;
        height: 4.8rem;
        padding: 1.2rem 2.4rem;
        text-align: center;
        text-decoration: none;
        width: 48%;
      }

      ${Decline} {
        background: white;
        border-radius: 0.6rem;
        border: solid 0.1rem #0084ff;
        color: #0084ff;
        cursor: pointer;
        font-size: 1.4rem;
        height: 4.8rem;
        padding: 1.2rem 2.4rem;
        text-align: center;
        text-decoration: none;
        width: 48%;
      }
    }
  }

  @media screen and (min-width: 1280px) {
    height: 6.2rem;

    ${Wrapper} {
      margin: 0 11.2rem;
      padding: 0;

      ${Content} {
        flex: 4;

        ${Disclaimer} {
          display: inline-block;
          margin-left: 1.2rem;
          margin-top: 0;
        }
      }
      ${Actions} {
        justify-content: space-around;
        margin: 0 !important;

        ${Agree} {
          width: 10.5rem;
        }

        ${Decline} {
          width: 10.5rem;
        }
      }
    }
  }

  @media screen and (min-width: 1440px) {
    ${Wrapper} {
      max-width: 121.6rem;
      margin: 0 auto;
    }
  }
`;
