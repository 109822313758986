import PropTypes from 'prop-types';
import { intlShape } from '@react-intl';

export default {
  ...PropTypes,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  intl: intlShape,
};
