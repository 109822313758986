import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedTime as Time } from 'react-intl';

const FormattedTime = ({
  component: Wrapper = 'span',
  forwardedRef,
  timeZone,
  timeZoneName,
  value,
  ...props
}) => (
  <Time
    timeZone={timeZone}
    timeZoneName={timeZoneName}
    value={value}
    {...props}
  >
    {text => (
      <Wrapper {...props} ref={forwardedRef}>
        {text}
      </Wrapper>
    )}
  </Time>
);

FormattedTime.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
  ]),
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  timeZone: PropTypes.string,
  timeZoneName: PropTypes.string,
  value: PropTypes.any,
};

export default memo(
  // eslint-disable-next-line react/display-name
  forwardRef((props, forwardedRef) => (
    <FormattedTime {...props} forwardedRef={forwardedRef} />
  )),
);
