import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

export const NavBar = ({ className, children }) => (
  <nav className={className}>{children}</nav>
);

NavBar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default styled(NavBar)`
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;

  * {
    cursor: pointer;
    margin-left: 3.2rem;
  }

  @media screen and (max-width: 600px) {
    background: #003566;
    box-shadow: 0 0.1rem 0.6rem rgba(255, 255, 255, 0.2);
    flex-direction: column;
    position: fixed;
    top: 7rem;
    transform: translateY(${props => (props.show ? '0' : '-100vh')});
    transition: transform 0.5s ease-in-out;
    z-index: -1;

    * {
      margin: 1.6rem 0;
      height: 4.8rem;
      width: 100%;
    }
  }
`;
