import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedRelativeTime as RelativeTime } from 'react-intl';

const FormattedRelativeTime = ({
  component: Wrapper = 'span',
  forwardedRef,
  value,
  ...props
}) => (
  <RelativeTime value={value} {...props}>
    {text => (
      <Wrapper {...props} ref={forwardedRef}>
        {text}
      </Wrapper>
    )}
  </RelativeTime>
);

FormattedRelativeTime.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
  ]),
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  value: PropTypes.any,
};

export default memo(
  // eslint-disable-next-line react/display-name
  forwardRef((props, forwardedRef) => (
    <FormattedRelativeTime {...props} forwardedRef={forwardedRef} />
  )),
);
