import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'Components/I18n';

import Layout from 'Layouts/default';

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

wrapPageElement.propTypes = {
  element: PropTypes.elementType,
  props: PropTypes.object,
};

export const wrapRootElement = ({ element }) => (
  <I18n locale="en">{element}</I18n>
);

wrapRootElement.propTypes = {
  element: PropTypes.elementType,
};
