import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage as HTMLMessage } from 'react-intl';

import transform from './transform';

const FormattedHTMLMessage = ({
  children,
  capitalize = false,
  component: Wrapper = 'span',
  defaultMessage = Array.isArray(children) ? children.join('') : children,
  forwardedRef,
  id = defaultMessage,
  uppercase = false,
  values,
  ...props
}) => (
  <HTMLMessage
    {...props}
    defaultMessage={defaultMessage}
    id={id}
    values={values}
  >
    {html => (
      <Wrapper
        {...props}
        dangerouslySetInnerHTML={{
          __html: transform(html, { capitalize, uppercase }),
        }}
        ref={forwardedRef}
      />
    )}
  </HTMLMessage>
);

FormattedHTMLMessage.propTypes = {
  capitalize: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
  ]),
  defaultMessage: PropTypes.string,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  id: PropTypes.string,
  uppercase: PropTypes.bool,
  values: PropTypes.object,
};

export default memo(
  // eslint-disable-next-line react/display-name
  forwardRef((props, forwardedRef) => (
    <FormattedHTMLMessage {...props} forwardedRef={forwardedRef} />
  )),
);
