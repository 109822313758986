import React, { PureComponent } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import PropTypes from 'prop-types';
import styled from '@styled-components';
import { FormattedMessage } from '@react-intl';

import Logotype from 'Components/Logotype';
import NavBar from 'Components/NavBar';

const Wrapper = styled('section')``;
const Link = styled(GatsbyLink)``;
const Login = styled(OutboundLink)``;
const Img = styled('img')``;
const Icon = styled('button')``;

const WFTOWrapper = styled(GatsbyLink)``;
const WFTOLOGO = styled('img')``;
const WFTOText = styled('p')``;

const WFTOContent = styled('div')`
  left: ${props => props.left};
`;

const WTCOComponent = ({ left }) => (
  <WFTOContent left={left}>
    <WFTOText>WFTO Members Exclusive Offer</WFTOText>
    <WFTOLOGO alt="WFTO" src="/images/WFTO.png" />
  </WFTOContent>
);

WTCOComponent.propTypes = {
  left: PropTypes.string,
};

class Header extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
  };

  state = { extended: false, scroll: false };

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  closeNavBar = () => {
    this.setState({ extended: false });
  };

  toggleNavBar = () => {
    this.setState(prevState => ({ extended: !prevState.extended }));
  };

  handleScroll = () => {
    this.setState({ scroll: Math.max(0, Math.min(1, window.scrollY / 82)) }); // 82 px is header's total height
  };

  render() {
    const { className } = this.props;
    const { extended, scroll } = this.state;

    return (
      <header
        className={className}
        style={{
          boxShadow: scroll === 1 ? '0 1px 0 0 rgba(0, 0, 0, 0.1)' : 'none',
        }}
      >
        <WFTOWrapper to="/wfto">
          <WTCOComponent left="-35rem" />
          <WTCOComponent left="0" />
          <WTCOComponent left="35rem" />
          <WTCOComponent left="70rem" />
          <WTCOComponent left="105rem" />
          <WTCOComponent left="140rem" />
          <WTCOComponent left="175rem" />
          <WTCOComponent left="210rem" />
          <WTCOComponent left="245rem" />
          <WTCOComponent left="280rem" />
          <WTCOComponent left="315rem" />
        </WFTOWrapper>
        <Wrapper>
          <Link to="/" itemScope itemType="http://schema.org/Organization">
            <Logotype />
          </Link>
          <NavBar show={extended}>
            <FormattedMessage
              capitalize
              component={Link}
              id="REQUEST_DEMO"
              to="/demo"
              {...(extended && { onClick: this.closeNavBar })}
            />
            <FormattedMessage
              capitalize
              component={Link}
              id="JOBS"
              to="/jobs"
              {...(extended && { onClick: this.closeNavBar })}
            />
            <FormattedMessage
              capitalize
              component={Link}
              id="SOLUTION"
              to="/solution"
              {...(extended && { onClick: this.closeNavBar })}
            />
            <FormattedMessage
              capitalize
              component={Link}
              id="BLOG"
              to="/blog"
              {...(extended && { onClick: this.closeNavBar })}
            />
            <FormattedMessage
              capitalize
              component={Link}
              id="LEADERSHIP_TEAM"
              to="/team"
              {...(extended && { onClick: this.closeNavBar })}
            />
            <FormattedMessage
              capitalize
              component={Login}
              href="https://admin.joined.app"
              id="LOGIN"
              rel="noopener"
              target="_blank"
              {...(extended && { onClick: this.closeNavBar })}
            />
          </NavBar>
          <Icon open={extended} onClick={this.toggleNavBar}>
            {extended ? (
              <Img alt="Close menu" src="/images/close.svg" />
            ) : (
              <Img alt="Open menu" src="/images/menu.svg" />
            )}
          </Icon>
        </Wrapper>
      </header>
    );
  }
}

export default styled(Header)`
  background-color: #003566;
  box-sizing: border-box;
  height: 14.4rem;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 20;

  & + * {
    padding-top: 14.4rem;
  }

  ${WFTOWrapper} {
    background-color: #0095ff;
    display: block;
    height: 6.2rem;
    width: 100%;
    min-width: 140rem;
    position: relative;
  }

  ${WFTOContent} {
    align-items: center;
    animation: ticker 5s linear infinite;
    color: #ffffff;
    display: flex;
    font-size: 1.6rem;
    height: 6.2rem;
    margin-right: 5rem;
    position: absolute;
    width: 30rem;
  }

  ${WFTOLOGO} {
    height: 4.8rem;
    margin-left: 1.2rem;
    width: 4.8rem;
  }

  ${WFTOText} {
    width: 25rem;
  }

  ${Wrapper} {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 3.2rem;
    padding: 2rem 0;
    position: relative;
  }

  ${NavBar} {
    display: flex;
    font-size: 1.6rem;
    justify-content: flex-end;
    padding: 0;
    top: 8.2rem;

    ${Link} {
      font-size: 1.5rem;
      cursor: pointer;
      line-height: 1.6;
      opacity: 0.6;
      text-align: center;
      color: #ffffff;
      border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);

      &:hover {
        border-bottom: 0.1rem solid rgba(255, 255, 255, 0.6);
        opacity: 1;
      }
      &:focus {
        outline: 0;
      }
    }

    ${Login} {
      background-image: linear-gradient(69deg, #005dff, #0095ff);
      border-radius: 0.6rem;
      color: white;
      cursor: pointer;
      font-size: 1.4rem;
      height: 4.8rem;
      padding: 1.6rem;
      text-align: center;
      text-decoration: none;
      width: 10.5rem;
    }
  }

  ${Icon} {
    display: none;
  }

  @media screen and (min-width: 1280px) {
    ${Wrapper} {
      margin: 0 11.2rem;
    }
  }

  @media screen and (min-width: 1440px) {
    ${Wrapper} {
      max-width: 121.6rem;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 600px) {
    ${Wrapper} {
      background: #003566;
      justify-content: flex-start;
      height: 8.2rem;
      margin: 0;
      padding: 0 2.4rem;
      ${NavBar} {
        position: absolute;
        left: 0;
        height: calc(100vh - 8.2rem);
        justify-content: center;
        ${Login} {
          background: none;
          border-radius: 0;
          width: 100%;
        }
        ${Link}, ${Login} {
          border: none;
          color: white;
          font-size: 2.4rem;
          font-weight: 500;
          letter-spacing: -0.15rem;
          line-height: 1.29;
          opacity: 0.6;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      ${Icon} {
        background-color: transparent;
        background-repeat: no-repeat;
        border: none;
        display: block;
        height: 4.8rem;
        position: absolute;
        right: 2.4rem;
        width: 4.8rem;

        :focus {
          outline-color: rgba(0, 149, 255, 0.4);
        }

        ${Img}, ${WFTOLOGO} {
          height: 2.4rem;
          width: 2.4rem;
        }
      }
    }
  }

  @keyframes ticker {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(35rem);
    }
  }
`;
