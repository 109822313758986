import { withTranslation } from '@react-intl';
import styled from 'styled-components';
import { compose } from 'underscore';

export {
  css,
  createGlobalStyle,
  createGlobalStyle as injectGlobal,
  keyframes,
  ServerStyleSheet,
  ThemeProvider,
  withTheme,
} from 'styled-components';

const theme = key => props => props.theme[key];
const prop = key => props => props[key];

const withStyles = (styles, ...interpolations) => {
  return function Styles(WrappedComponent) {
    return styled(WrappedComponent)(styles, ...interpolations);
  };
};

const xtyled = (WrappedComponent, options = { attrs: [], config: [] }) => {
  const templateFunction = (...argv) => {
    let Component = compose(
      styled,
      withTranslation,
    )(WrappedComponent);

    Component = options.attrs.reduce(
      (acc, args) => acc.attrs(...args),
      Component,
    );

    Component = options.config.reduce(
      (acc, args) => acc.withConfig(...args),
      Component,
    );

    return Component(...argv);
  };

  templateFunction.attrs = (...argv) =>
    xtyled(WrappedComponent, {
      ...options,
      attrs: [...options.attrs, argv],
    });

  templateFunction.withConfig = (...argv) =>
    xtyled(WrappedComponent, {
      ...options,
      config: [...options.attrs, argv],
    });

  return templateFunction;
};

export { prop, theme, withStyles };

export default xtyled;
