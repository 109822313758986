import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import Address from './Address';
import Media from './Media';
import Contact from './Contact';

const Wrapper = styled('section')``;
const Bottom = styled('section')``;

const Footer = ({ className }) => (
  <footer className={className}>
    <Wrapper>
      <Address />
      <Bottom>
        <Contact />
        <Media />
      </Bottom>
    </Wrapper>
  </footer>
);

Footer.propTypes = {
  className: PropTypes.string,
};

export default styled(Footer)`
  bottom: 0;
  left: 0;
  position: relative;
  margin: 0 3.2rem 6.4rem 3.2rem;

  ${Wrapper} {
    width: 100%;
  }

  & ${Address} {
    width: 100%;
  }

  ${Bottom} {
    border-top: 0.1rem solid rgba(0, 53, 102, 0.05);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 2.4rem;
    width: 100%;
  }

  @media screen and (min-width: 600px) {
    & ${Address} {
      margin-bottom: 5.5rem;
      min-width: 20rem;
      width: 65%;
    }
  }

  @media screen and (min-width: 1280px) {
    ${Wrapper} {
      margin: 0 auto;
      max-width: 121.6rem;
    }

    & ${Address} {
      min-width: 20rem;
      width: 50%;
    }
  }

  @media screen and (max-width: 600px) {
    ${Wrapper} {
      color: white;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      min-height: 20rem;
      padding: 2.4rem;
    }

    ${Contact} {
      padding: 2.4rem 0;
      width: 100%;
      * {
        margin: 0;
      }
    }

    ${Media} {
      padding-top: 1.6rem;
      width: 100%;
      * {
        margin: 0;
      }
    }
  }
`;
