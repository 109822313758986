import React from 'react';
import { Link as RawLink } from 'gatsby';
import PropTypes from 'prop-types';
import styled from '@styled-components';
import { FormattedMessage } from '@react-intl';

const Link = styled('a')``;
const GatsbyLink = styled(RawLink)``;

const Contact = ({ className }) => (
  <section
    className={className}
    itemScope
    itemType="http://schema.org/ContactPoint"
  >
    <FormattedMessage
      capitalize
      component={Link}
      href="mailto:info@joinedapp.com"
      id="CONTACT_US"
      itemProp="email"
    >
      contact us
    </FormattedMessage>
    <FormattedMessage capitalize component={GatsbyLink} id="TERMS" to="/terms">
      terms and conditions
    </FormattedMessage>
    <FormattedMessage
      capitalize
      component={GatsbyLink}
      id="PRIVACY"
      to="/privacy"
    >
      privacy policy
    </FormattedMessage>
  </section>
);

Contact.propTypes = {
  className: PropTypes.string,
};

export default styled(Contact)`
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  justify-content: space-around;
  text-align: center;

  ${Link}, ${GatsbyLink} {
    height: 4.8rem;
    color: #000f19;
    cursor: pointer;
    margin-right: 2.4rem;
    opacity: 0.6;
  }
`;
