import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedPlural as Plural } from 'react-intl';

const FormattedPlural = ({
  component: Wrapper = 'span',
  few,
  forwardedRef,
  many,
  one,
  other,
  two,
  zero,
  ...props
}) => (
  <Plural few={few} many={many} one={one} other={other} zero={zero} {...props}>
    {text => (
      <Wrapper {...props} ref={forwardedRef}>
        {text}
      </Wrapper>
    )}
  </Plural>
);

FormattedPlural.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
  ]),
  few: PropTypes.string,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  many: PropTypes.string,
  one: PropTypes.string,
  other: PropTypes.string,
  two: PropTypes.string,
  zero: PropTypes.string,
};

export default memo(
  // eslint-disable-next-line react/display-name
  forwardRef((props, forwardedRef) => (
    <FormattedPlural {...props} forwardedRef={forwardedRef} />
  )),
);
