import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from '@react-intl';

import en from 'Translations/en.json';

const MESSAGES = { en };
/* Main Component */
export const I18n = ({ children, locale: rawLocale = 'en' }) => {
  /* Sometimes locale is sent as an array */
  const locale = Array.isArray(rawLocale) ? rawLocale[0] : rawLocale;
  const [language] = locale.split(/[-_]/);

  return (
    <IntlProvider
      locale={locale.replace('_', '-')}
      messages={{ ...en, ...MESSAGES[language] }}
    >
      {children}
    </IntlProvider>
  );
};

I18n.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  locale: PropTypes.string,
};

export default I18n;
