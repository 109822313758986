module.exports = [{
      plugin: require('/home/circleci/project/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"anonymize":true,"head":false,"respectDNT":true,"siteSpeedSampleRate":10,"trackingId":"UA-166756783-1"},
    },{
      plugin: require('/home/circleci/project/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WZX4BJ4","includeInDevelopment":true},
    },{
      plugin: require('/home/circleci/project/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
