import React from 'react';
import PropTypes from '@prop-types';
import { intlDefault } from '@react-intl';

import Cookies from 'Components/Gdpr';
import Footer from 'Components/Footer';
import SEO from 'Components/SEO';
// old header
// import Header from 'Components/Header';
// temp header
import TempHeader from 'Components/TempHeader';

const Default = ({ children }) => (
  <>
    {/* old header */}
    {/* <Header /> */}

    {/* temp header */}
    <TempHeader />
    <SEO />
    <main>{children}</main>
    <Footer />
    <Cookies />
    <div id="video" />
  </>
);

Default.defaultProps = {
  children: intlDefault,
};

Default.propTypes = {
  children: PropTypes.children,
};

export default Default;
